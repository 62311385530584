import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
} from "formik";
import { useState } from "react";
import { postCollabThread, updateCollabThread } from "../../api";
import {
  CollabThreadParams,
  CollaborationThread,
  PydanticError,
} from "../../api/types";
import FormTextField from "../Form/FormTextField";
import Spinner from "../Spinner";
import TinyMCEEditorNoToolbar from "../TinyMCEEditorBasic";
import { classNames } from "../utils";
import { ForumFormValues, threadToFormValues } from "./ForumUtils";

type Prompt = {
  promptText: string;
  titleText: string;
  descriptionText: string;
};

const prompts: Prompt[] = [
  {
    promptText: "Expertise in an area",
    titleText:
      "Request for Contribute of Expertise on Investment Due Diligence",
    descriptionText: `Would appreciate your contribution by sharing your views as a subject matter expert in the topic of semiconductor. Your perspective will provide a comprehensive understanding of the practical implications and applications of investment due diligence.`,
  },
  {
    promptText: "Connections to company",
    titleText: "Request to connect to Forbes 100 Companies",
    descriptionText: `Would like to request for connection to Forbes 100 Companies to help our portfolio company with their BD in Singapore`,
  },
  {
    promptText: "Review tools/solutions",
    titleText: "Help to review tools/solutions",
    descriptionText: `Would like to get help with trying a product, a drag and drop website builder. Any feedback will be appreciated.`,
  },
];

type ThreadFormProps = {
  thread?: CollaborationThread;
  onClose: () => void;
  onSuccess: (thread: CollaborationThread) => void;
};

export const ThreadForm = ({ thread, onClose, onSuccess }: ThreadFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const emptyForm: ForumFormValues = {
    title: "",
    content: "",
  };

  const initData = thread ? threadToFormValues(thread) : emptyForm;

  const handleSubmit = async (
    values: ForumFormValues,
    formikHelpers: FormikHelpers<ForumFormValues>,
  ) => {
    const { setErrors, setStatus } = formikHelpers;

    let payload: CollabThreadParams = {
      title: values.title,
      content: values.content,
    };

    setIsSubmitting(true);
    const apiCaller = thread
      ? updateCollabThread(thread.id, payload)
      : postCollabThread(payload);
    return apiCaller
      .then((res) => {
        if (res.data.data) {
          onSuccess(res.data.data);
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 422:
            const errors: PydanticError = error.response.data;
            // formik errors from pydantic body errors
            setErrors(
              Object.fromEntries(errors.detail.map((v) => [v.loc[2], v.msg])),
            );
            return;
        }
        setStatus(error.response.data);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const validate = (values: ForumFormValues) => {
    let errors: FormikErrors<ForumFormValues> = {};

    if (!values.title) {
      errors.title = "Title is required";
    }

    return errors;
  };
  return (
    <Formik<ForumFormValues>
      initialValues={initData}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          <div className="mt-5 h-full items-center rounded-xl bg-gray-100 p-5 md:mb-5">
            <div className="mb-3 flex w-full flex-wrap gap-1">
              {prompts.map((prompt: Prompt) => (
                <div
                  key={prompt.promptText}
                  className="text-2xs cursor-pointer rounded-full border border-gray-400 bg-white p-2 text-center font-medium hover:bg-blue-100 hover:text-blue-900"
                  onClick={() => {
                    setFieldValue("title", prompt.titleText);
                    setFieldValue("content", prompt.descriptionText);
                  }}
                >
                  {prompt.promptText}
                </div>
              ))}
            </div>
            <div>
              <Field
                as={FormTextField}
                name="title"
                label="Title"
                errors={errors.title}
                touched={touched.title}
                required
              />
              <div className="z-0 mt-5">
                <label className="flex">
                  <div className="flex text-xs font-semibold">Description</div>
                </label>
                <Field name="content">
                  {({ field: { value } }: FieldProps) => (
                    <TinyMCEEditorNoToolbar
                      height={300}
                      value={value}
                      handleEditorChange={(stringifiedHtmlValue) => {
                        setFieldValue("content", stringifiedHtmlValue);
                      }}
                    />
                  )}
                </Field>
                <div
                  className={classNames(
                    errors.content && errors.content !== undefined
                      ? "text-2xs text-red-600"
                      : "hidden",
                  )}
                >
                  {errors.content}
                </div>
              </div>
              <div className="mt-6 flex flex-row justify-end space-x-4 md:col-start-2">
                <button className="btn-secondary" onClick={() => onClose()}>
                  Close
                </button>
                <button className="btn btn-primary">
                  {isSubmitting ? (
                    <div className="flex flex-row items-center gap-x-2 text-white">
                      <div>
                        <Spinner className="text-blue h-4 w-4" />
                      </div>
                      <div>Submitting</div>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
